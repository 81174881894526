<template>
    <div class="utils">
        <div class="row">
            <div class="col-sm-6 col-md-4 col-xl-3">
                <ui-card title="Screening files">
                    <img
                        alt="Screening"
                        class="utils__icon"
                        src="@/assets/img/utils/screening.svg"
                    />
                    <ui-button
                        icon="fas fa-download"
                        variant="primary"
                        :loading="downloadingScreenings"
                        @click="downloadScreenings()"
                    >
                        Download
                    </ui-button>
                </ui-card>
            </div>
        </div>
        <download-report-snackbar ref="downloadReportSnackbar" @downloadFinished="downloadingScreenings = false" />
    </div>
</template>
<script>
import DownloadReportSnackbar from '@/components/reports/DownloadReportSnackbar.vue';
import UiButton from '@/components/ui/buttons/Button';
import UiCard from '@/components/ui/Card.vue';

export default {
    name: 'Reports',
    components: {
        UiButton,
        UiCard,
        DownloadReportSnackbar
    },
    data() {
        return {
            downloadingScreenings: false,
        };
    },
    computed: {
        downloadLink() {
            return '/_/screening/create';
        } 
    },
    methods: {
        downloadScreenings() {
            this.$refs.downloadReportSnackbar.download({name: 'Screening', url: this.downloadLink});
            this.downloadingScreenings = true;
        },
    },
};
</script>

<style lang="scss">
.utils {
    &__icon {
        height: 100%;
        margin: 0 auto;
        max-height: 100px;
        width: auto;
    }
}
</style>
